*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-Italic.ttf);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-Bold.ttf);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/static/Montserrat-BlackItalic.ttf);
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
